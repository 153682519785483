import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  // Hidden,
  // IconButton,
  Typography,
} from '@mui/material';
// import AppLngSwitcher from '../../../AppLngSwitcher';
// import AppSearchBar from '../../../AppSearchBar';
// import MenuIcon from '@mui/icons-material/Menu';
// import AppMessages from '../../../AppMessages';
// import AppNotifications from '../../../AppNotifications';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppLogo from '../../components/AppLogo';
import UserInfo from '../../components/UserInfo';
import {Fonts} from '@crema/constants/AppEnums';
import {useNavigate} from 'react-router-dom';

const AppHeader = ({toggleNavCollapsed}: any) => {
  const navigate = useNavigate();
  // appbar
  // app header file
  //navbar file
  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        borderBottom: `1px solid primary`,
        backgroundColor: '#042054',
        transition: 'width 0.3s ease',
        width: '100%',
      }}
      className='app-bar'
    >
      <Toolbar
        sx={{
          // minHeight: {xs: 56, sm: 70},
          minHeight: '6vh',
          maxHeight: '6vh',
          height: '6vh',
          paddingLeft: {xs: 6},
          paddingRight: {xs: 6, md: 8, xl: 12},
        }}
      >
        {/* <Hidden lgUp>
          <IconButton
            sx={{
              color: 'text.secondary',
            }}
            edge='start'
            className='menu-btn'
            color='inherit'
            aria-label='open drawer'
            onClick={() => toggleNavCollapsed()}
            size='large'
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            gap: 2,
            '& .logo-text': {
              display: {xs: 'none', sm: 'block'},
            },
          }}
          onClick={() => navigate('/')}
        >
          <AppLogo />
          <Typography
            sx={{fontSize: 16, color: 'white', fontWeight: Fonts.BOLD}}
          >
            Admin Sellers Route
          </Typography>
        </Box>
        <Box sx={{flexGrow: 1}} />
        {/* <Box
          sx={{
            minHeight: 40,
            position: 'relative',
            display: {xs: 'none', sm: 'block'},
            '& .searchRoot': {
              position: {xs: 'absolute', sm: 'relative'},
              right: {xs: 0, sm: 'auto'},
              top: {xs: 0, sm: 'auto'},
            },
          }}
        >
          <AppSearchBar iconPosition='right' placeholder='Search…' />
        </Box> */}
        {/* <Box sx={{ml: 4}}>
          <AppLngSwitcher iconOnly={false} tooltipPosition='bottom' />
        </Box> */}
        <Box
          sx={{
            ml: 4,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* <Hidden smDown>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppNotifications />
              </Box>
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppMessages />
              </Box>
            </Box>
          </Hidden> */}
          <Box
            sx={{
              ml: {sm: 4},
              mr: {xs: 4, sm: 0},
              minWidth: {md: 220},
              '& .user-info-view': {
                p: 0,
              },
              '& .user-info': {
                display: {
                  xs: 'none',
                  md: 'block',
                  lg: 'block',
                  xl: 'block',
                  xxl: 'block',
                },
              },
            }}
          >
            <UserInfo />
          </Box>

          {/* <Hidden smUp>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppTooltip title='More'>
                  <IconButton
                    sx={{
                      borderRadius: '50%',
                      width: 40,
                      height: 40,
                      color: (theme) => theme.palette.text.secondary,
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                      border: 1,
                      borderColor: 'transparent',
                      '&:hover, &:focus': {
                        color: (theme) => theme.palette.text.primary,
                        backgroundColor: (theme) =>
                          alpha(theme.palette.background.default, 0.9),
                        borderColor: (theme) =>
                          alpha(theme.palette.text.secondary, 0.25),
                      },
                    }}
                    onClick={handleClick}
                    size='large'
                  >
                    <MoreVertIcon />
                  </IconButton>
                </AppTooltip>
              </Box>
            </Box>
          </Hidden> */}
          {/* <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <AppNotifications isMenu />
            </MenuItem>
            <MenuItem>
              <AppMessages isMenu />
            </MenuItem>
            <MenuItem>Setting</MenuItem>
          </Menu> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
