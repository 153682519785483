/* eslint-disable prettier/prettier */
import { authRole } from '@crema/constants/AppConst';

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.User,
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.name ? user.name : 'Crema User',
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
      role: authRole.User,
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromJwtAuth = (user: any) => {
  // console.log('user', user);
  if (user)
    return {
      uid: user._id,
      name: user.name,
      profileImage: user.profilePic,
      accountCount: user.accountCount,
      accountsLeft: user.accountsLeft,
      createdAt: user.createdAt,
      email: user.email,
      isSubscribed: user.isSubscribed,
      password: user?.password,
      prospectCount: user.prospectCount,
      type: user.type,
      updatedAt: user.updatedAt,
      verified: user.verified,
      version: user.__v
    };
  return user;
};
