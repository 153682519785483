import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import AppLoader from '@crema/components/AppLoader';
const HomeTable = React.lazy(() => import('../../../modules/analytics'));

export const analyticsConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/home',
    element: (
      <Suspense fallback={<AppLoader />}>
        <HomeTable />
      </Suspense>
    ),
  },
];
