import React from 'react';
import {Box} from '@mui/material';
import Logo from '../../../../../assets/image.png';

const AppLogo = () => {
  return (
    <Box
      sx={{
        height: {xs: 56, sm: 70},
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '.png': {
          height: {xs: 25, sm: 30, md: 35, lg: 35, xl: 37.5},
        },
      }}
    >
      <img src={Logo} alt='sellers_route' className='png' />
    </Box>
  );
};

export default AppLogo;
