import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {AuthUserType} from '@crema/types/models/AuthUser';
import jwtAxios, {setAuthToken} from './index';
import {useInfoViewActionsContext} from '@crema/context/AppContextProvider/InfoViewContextProvider';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'toolkit/hooks';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
} from '@crema/types/actions/Common.action';
import {
  SET_ACCOUNTS_DATA,
  SET_ALL_USERS_DATA,
  SET_PROSPECT_DATA,
} from '@crema/types/actions/Dashboard.action';
interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}
interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);
export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const infoViewActionsContext = useInfoViewActionsContext();

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem('adminSellersRouteToken');
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      try {
        const res = await jwtAxios.get('/user/getMe');
        setJWTAuthData({
          user: res?.data?.responseData[0],
          isAuthenticated: true,
          isLoading: false,
        });
      } catch (error) {
        localStorage.removeItem('adminSellersRouteToken');
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
      }
    };

    getAuthUser();
  }, []);

  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    infoViewActionsContext.fetchStart();
    try {
      const {data} = await jwtAxios.post('/auth/admin/login', {
        email,
        password,
      });
      localStorage.setItem('adminSellersRouteToken', data?.data?.token);
      setAuthToken(data?.data.token);
      const res = await jwtAxios.get('/user/getMe');
      setJWTAuthData({
        user: res?.data?.responseData[0],
        isAuthenticated: true,
        isLoading: false,
      });
      infoViewActionsContext.fetchSuccess();
      dispatch({type: SHOW_MESSAGE, payload: 'Successfully Logged In'});
    } catch (error) {
      localStorage.removeItem('adminSellersRouteToken');
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError(error?.response?.data?.msg);
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: error.response.data.message,
      });
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
    confirmPassword,
  }: {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    infoViewActionsContext.fetchStart();
    try {
      await jwtAxios.post('auth/teacher/signup', {
        name,
        email,
        password,
        confirmPassword,
      });
      infoViewActionsContext.fetchSuccess();
      dispatch({
        type: SHOW_MESSAGE,
        payload: 'You are not verified, wait till admin verify you',
      });
      setTimeout(() => {
        navigate('/signin');
        dispatch({
          type: SHOW_MESSAGE,
          payload: null,
        });
      }, 600);
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError('Something went wrong');
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: error.response.data.message,
      });
    }
  };

  const logout = async () => {
    try {
      dispatch({type: FETCH_START});
      await jwtAxios.post('auth/user/logout');
      localStorage.removeItem('adminSellersRouteToken');
      setAuthToken();
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error.response.data.message});
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: error.response.data.message,
      });
    }
    dispatch({type: SET_PROSPECT_DATA, payload: []});
    dispatch({type: SET_ACCOUNTS_DATA, payload: []});
    dispatch({type: SET_ALL_USERS_DATA, payload: []});
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    dispatch({type: SHOW_MESSAGE, payload: 'Logged Out'});
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
