import React from 'react';
import './loader.css';
import {CircularProgress} from '@mui/material';

const AppLoader = () => {
  return (
    <div className='app-loader'>
      <CircularProgress
        color='success'
        sx={{
          animation: 'all 5s',
          opacity: '0.6',
          animationDuration: '5s',
        }}
      />
      {/* <div className='loader-spin'>
        <span className='crema-dot crema-dot-spin'>
        
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div> */}
    </div>
  );
};

export default AppLoader;
