/* eslint-disable prettier/prettier */
import {
    FETCH_ERROR,
    SHOW_MESSAGE,
    SHOW_DELETE_MODAL,
    SHOW_ERROR_MESSAGE,
} from '@crema/types/actions/Common.action';
import { createAction } from '@reduxjs/toolkit';

export const ShowMsgAction = createAction<string>(SHOW_MESSAGE);
export const FetchErrorAction = createAction<string>(FETCH_ERROR);
export const ShowDeleteModal = createAction<boolean>(SHOW_DELETE_MODAL);
export const ShowErrorMessage = createAction<string>(SHOW_ERROR_MESSAGE);
