import React from 'react';
// import {RoutePermittedRole} from '@crema/constants/AppEnums';
import {IoHomeOutline} from 'react-icons/io5';

const routesConfig = [
  {
    id: 'home',
    title: 'Home',
    messageId: 'Home',
    type: 'item',
    // permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    icon: <IoHomeOutline />,
    url: '/home',
  },
  // {
  //   id: 'manage',
  //   title: 'Manage',
  //   messageId: 'sidebar.manage',
  //   type: 'group',
  //   children: [
  //     {
  //       id: 'courses',
  //       title: 'Courses',
  //       messageId: 'sidebar.manage.courses',
  //       type: 'collapse',
  //       icon: <MdOutlineLibraryBooks />,
  //       children: [
  //         {
  //           id: 'create',
  //           title: 'Create',
  //           messageId: 'sidebar.manage.courses.create',
  //           type: 'item',
  //           icon: <BiSolidAddToQueue />,
  //           url: '/course/create',
  //         },
  //       ],
  //     },
  //   ],
  // },
];
export default routesConfig;
