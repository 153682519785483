/* eslint-disable prettier/prettier */
export const SET_ALL_USERS_DATA = 'SET_ALL_USERS_DATA';
export const SET_PROSPECT_DATA = 'SET_PROSPECT_DATA';
export const SET_ACCOUNTS_DATA = 'SET_ACCOUNTS_DATA';

export type setAllUsersData = {
  type: typeof SET_ALL_USERS_DATA;
  payload: any;
};

export type setProspectsData = {
  type: typeof SET_PROSPECT_DATA,
  payload: any;
};

export type setAccountsData = {
  type: typeof SET_ACCOUNTS_DATA;
  payload: any;
};

export type DashboardActionTypes =
  | setAllUsersData
  | setProspectsData
  | setAccountsData;
